import Vue from 'vue'
import Vuex from 'vuex'
import { ApiResponse, User, InvoiceFilter } from '@/interfaces'
import axios from '@/axios'

Vue.use(Vuex)

let busy = false

export default new Vuex.Store({
  state: {
    user: null as User | null,
    scrollPosition: { x: 0, y: 0 },
    connectedAs: null as null | string,
    invoicesFilters: {} as InvoiceFilter,
  },
  mutations: {
    logout(state) {
      state.user = null
      state.connectedAs = null
    },
    setUser(state, userInfos) {
      state.user = userInfos
    },
    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
    setConnectedAs(state, email) {
      state.connectedAs = email
    },
    setInvoicesFilters(state, filters) {
      state.invoicesFilters = filters
    },
  },
  actions: {
    logout: ({ commit }) => commit('logout'),
    setUser: ({ commit }) => commit('setUser'),
    setScrollPosition: ({ commit }) => commit('setScrollPosition'),
    loadAppData: ({ commit, state }) => {
      // Getting user
      if (busy === false) {
        busy = true

        return axios
          .get('/me', {
            params: {
              oldUser: state.user?.id,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            // Hack, json ne supporte pas les bigint pour le code addon il faut donc le reconvertir a l'arrivée
            const addons = parseInt(apiResponse.data.client.addons ?? 0, 10)
            const me = apiResponse.data
            me.client.addons = addons
            commit('setUser', me)
            return apiResponse
          })
          .catch((error) => {
            if (error.response) {
              return error.response
            }
          })
          .finally(() => {
            busy = false
          })
      }
    },
    setConnectedAs: ({ commit }, payload) => commit('setConnectedAs', payload),
    setInvoicesFilters: ({ commit }, payload) => commit('setInvoicesFilters', payload),
  },
  modules: {},
})
